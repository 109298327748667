import * as yup from 'yup';

export const schema = yup.object({
  cod_loja: yup.object().shape({
    label: yup.string().optional(),
    value: yup.number().required(),
  }),
  tipo_regime: yup.object().shape({
    label: yup.string().optional(),
    value: yup.number().required(),
  }),
  tipo_regime_fcp: yup.object().shape({
    label: yup.string().optional(),
    value: yup.number().required(),
  }),
  // tipo_integra_frente: yup.object().shape({
  //   label: yup.string().optional(),
  //   value: yup.number().required(),
  // }),
  // cod_interface_carga_pdv: yup.object().shape({
  //   label: yup.string().optional(),
  //   value: yup.number().required(),
  // }),
  tipo_regime_pis_cofins: yup.object().shape({
    label: yup.string().optional(),
    value: yup.number().required(),
  }),
  cod_cc_gare: yup.object().when('cod_pessoa_sefaz', {
    is: (selected: any) => {
      if (selected) return true;
      return false;
    },
    then: yup
      .object()
      .shape({
        label: yup.string().optional(),
        value: yup.number().required(),
      })
      .required(),
    otherwise: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .notRequired()
      .nullable(true),
  }),
  cod_finalizadora_gare: yup.object().when('cod_pessoa_sefaz', {
    is: (selected: any) => {
      if (selected) return true;
      return false;
    },
    then: yup
      .object()
      .shape({
        label: yup.string().optional(),
        value: yup.number().required(),
      })
      .required(),
    otherwise: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .notRequired()
      .nullable(true),
  }),
  cod_condicao_gare: yup.object().when('cod_pessoa_sefaz', {
    is: (selected: any) => {
      if (selected) return true;
      return false;
    },
    then: yup
      .object()
      .shape({
        label: yup.string().optional(),
        value: yup.number().required(),
      })
      .required(),
    otherwise: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .notRequired()
      .nullable(true),
  }),
  cod_categoria_gare: yup.object().when('cod_pessoa_sefaz', {
    is: (selected: any) => {
      if (selected) return true;
      return false;
    },
    then: yup
      .object()
      .shape({
        label: yup.string().optional(),
        value: yup.number().required(),
      })
      .required(),
    otherwise: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .notRequired()
      .nullable(true),
  }),
  val_desp_operacional: yup.string().required(),
});
